import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { startLoading, endLoading, sleep } from "@/utils/function";
import API from "@/api/teacher/article/articleCategory"
export default {
    name: "other",
    components: {},
    data() {
        return {
            // 搜索值
            input: "",
            // 当前页码
            page: 1,
            // 数据总条数
            dataTotal: 500,
            // 表格数据
            tableData: [],
            // dialog 显示标识
            dialogVisible: false,
            // 项目id
            category_id: "",
            // 是否是删除
            dialogSign: false,
            // 弹窗标题
            dialogTitle: "",
            // 分类名称
            categoryName: ""
        };
    },
    created() {
        const self = this;
        self.input = this.$router.currentRoute.query.search;
        self.page = Number(this.$router.currentRoute.query.page);
        // console.log(this.$router.currentRoute.query);
        self.getData();
    },
    methods: {
        /**
         * @name: 查找内容
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        searchContent() {
            const self = this;
            // 查询列表
            self.getData();
            // 路由传参
            self.$router.push({
                path: "/teacher/article/articleCategory",
                query: {
                    search: self.input,
                    page: self.page
                }
            });
        },

        /**
         * @name: 删除/修改/添加弹窗
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        handleOpen(sign, title, index = "", row = "") 
        {
            const self = this;
            self.dialogSign = sign;
            self.dialogTitle = title;
            self.dialogVisible = true;
            if (row) 
            {
                self.category_id = row.id;
            }
            if (sign == "is_update") 
            {
                const self = this;
                let data = {
                    category_id: self.category_id
                };
                // 请求数据
                API.getCategoryInfo(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.categoryName = result.info.name;
                            self.dialogVisible = true;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                    });
                // self.axios
                //     .get("/index.php/backarticle/getCategoryInfo", {
                //         params: data
                //     })
                //     .then(result => {
                //         self.categoryName = result.data.info.name;
                //         self.dialogVisible = true;
                //     })
                //     .catch(err => {});
            }
        },
        /**
         * @name: 弹窗确定键点击事件
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-01-11 10:25:50
         */
        dialogFix() 
        {
            const self = this;
            let data = {
                categoryName: self.categoryName,
                category_id: self.category_id
            };
            if (self.dialogSign == "is_delete") 
            {
                // 开启loading
                // startLoading();
                self.$Loading.show();
                // 请求数据
                API.deleteExec(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
                // self.axios
                //     .get("/index.php/backarticle/deleteExec", {
                //         params: data
                //     })
                //     .then(result => {
                //         self.getData();
                //         self.dialogVisible = false;
                //         // 关闭loading
                //         endLoading(startLoading()); 
                //         Message.success(result.data.msg);
                //     })
                //     .catch(err => {
                //         self.dialogVisible = false;
                //     });
            } 
            else
            {
                // 开启loading
                // startLoading();
                self.$Loading.show();
                // 请求数据
                API.updateArticleCategory(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
                // self.axios
                //     .get("/index.php/backarticle/updateArticleCategory", {
                //         params: data
                //     })
                //     .then(result => {
                //         self.getData();
                //         self.dialogVisible = false;
                //         self.categoryName = "";
                //         // 关闭loading
                //         endLoading(startLoading()); 
                //         Message.success(result.data.msg);
                //     })
                //     .catch(err => {});
            }
        },
        /**
         * @name: 获取显示数据
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        getData() 
        {
            const self = this;
            // 开启loading
            // startLoading();
            self.$Loading.show();
            let data = {
                page: self.page,
                search: self.input
            };
            // 请求数据
            API.backarticle(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.tableData = result.categoryList;
                            self.dataTotal = result.count;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide();
                    });
            // self.axios
            //     .get("/index.php/backarticle/", {
            //         params: data
            //     })
            //     .then(result => {
            //         self.tableData = result.data.categoryList;
            //         self.dataTotal = result.data.count;
            //         // 关闭loading
            //         endLoading(startLoading()); 
            //     })
            //     .catch(err => {});
        },
        /**
         * @name: 点击分页
         * @author: camellia
         * @date: 2020-12-29 16:38:39
         */
        handleCurrentChange(val) 
        {
            const self = this;
            self.page = val;
            self.getData();
            // 路由传参
            self.$router.push({
                path: "/teacher/article/articleCategory",
                query: {
                    search: self.input,
                    page: self.page
                }
            });
        }
    }
};
