import { request } from "@/request/http";

export default {

    updateArticleCategory: (data) => {
        return request("GET", `/index.php/updateArticleCategory/`, data);
    },
    deleteExec: (data) => {
        return request("GET", `/index.php/deleteExec/`, data);
    },
    getCategoryInfo: (data) => {
        return request("GET", `/index.php/getCategoryInfo/`, data);
    },
    backarticle: (data) => {
        return request("GET", `/index.php/backarticle/`, data);
    },
};