<template>
    <!-- 素材分类列表页 -->
    <div class="pdf">
        <!-- 搜索框 -->
        <div class="div-height div">
            <div class="left">
                <el-button
                    type="primary"
                    @click="handleOpen('is_add', '添加素材分类')"
                    >添加分类</el-button
                >
            </div>
            <div class="right">
                <el-input
                    placeholder="请输入内容"
                    v-model="input"
                    clearable
                    class="input-div"
                >
                </el-input>
                <el-button type="primary" @click="searchContent()"
                    >搜索</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div class="div">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column type="index" label="序号" width="180">
                </el-table-column>
                <el-table-column prop="name" label="分类名称">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="danger"
                            @click="
                                handleOpen(
                                    'is_delete',
                                    '删除素材分类',
                                    scope.$index,
                                    scope.row
                                )
                            "
                            >删除</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="
                                handleOpen(
                                    'is_update',
                                    '修改素材分类',
                                    scope.$index,
                                    scope.row
                                )
                            "
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="div-height padding-top div">
            <el-pagination
                @current-change="handleCurrentChange"
                background
                layout="pager"
                :total="dataTotal"
                :current-page.sync="page"
                v-if="dataTotal != 0"
            >
            </el-pagination>
        </div>

        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="40%"
        >
            <span>
                <span v-if="dialogSign != 'is_delete'">
                    <el-input
                        v-model="categoryName"
                        placeholder="请输入分类名称"
                    ></el-input>
                </span>
                <span v-else class="dialog-span">您确定要删除么？</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFix()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/article/articleCategory.scss";
</style>

<script>
import pdfDownload from "@/assets/js/teacher/article/articleCategory.js";
export default {
    ...pdfDownload
};
</script>
